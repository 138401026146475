import React from "react";
import {UserContext} from "../context/UserContext";
import {Navigate, NavLink} from "react-router-dom";
import {VerificationRequest} from "../components/VerificationRequest";
import {VerificationConfirm} from "../components/VerificationConfirm";
import {Alert, Col, ProgressBar, Row} from "react-bootstrap";
import {SignupForm} from "../components/SignupForm";

export const SignupPage: React.FunctionComponent = () => {
    const [verificationId, setVerificationId] = React.useState<string>("");
    const [secretId, setSecretId] = React.useState<string>("");
    const [isFinished, setFinished] = React.useState<boolean>(false);

    const {user} = React.useContext(UserContext);

    if (user) {
        return <Navigate to="/"/>
    }

    let child: React.JSX.Element;
    let progress: number;

    if (isFinished) {
        child = <Alert>Erfolgreich registriert! Du kannst dich jetzt einloggen</Alert>
        progress = 3;
    } else if (secretId) {
        const handleSuccess = () => {
            setFinished(true);
        };

        child = <SignupForm secret={secretId} onSuccess={handleSuccess}/>
        progress = 2;
    } else if (verificationId) {
        child = <VerificationConfirm verificationId={verificationId} setSecretId={setSecretId}/>
        progress = 1;
    } else {
        child = <VerificationRequest type="email" setVerificationId={setVerificationId}/>
        progress = 0;
    }

    const numSteps: number = 3;
    const progressLabel: string = isFinished ? "Fertig" : "Schritt " + (progress + 1) + " von " + numSteps;
    const progressValue: number = isFinished ? 100 : 100 * (progress + 1) / numSteps;
    const progressVariant: string = isFinished ? "success" : "primary";

    return <Row>
        <Col sm={{span: 4, offset: 4}}>
            <h1 className="text-center mb-3">Benutzer registrieren</h1>
            <div className="d-grid gap-3">
                <ProgressBar
                    animated={!isFinished}
                    now={progressValue}
                    label={progressLabel}
                    variant={progressVariant}
                />
                {child}
                <NavLink to="/login" className="text-center text-decoration-none">
                    Ich habe bereits einen Account
                </NavLink>
            </div>
        </Col>
    </Row>
}
