import React from "react";
import {Alert, Col, Row} from "react-bootstrap";
import {ChangePasswordForm} from "../../components/ChangePasswordForm";
import {NavLink} from "react-router-dom";

export const ChangePasswordPage: React.FunctionComponent = () => {
    const [isFinished, setFinished] = React.useState<boolean>(false);

    return <Row>
        <Col sm={{span: 4, offset: 4}}>
            <h1>Passwort ändern</h1>
            <div className="d-grid gap-3">
                {isFinished ? <>
                    <Alert variant="success">Passwort erfolgreich aktualisiert</Alert>
                </> : <>
                    <ChangePasswordForm onSuccess={() => setFinished(true)}/>
                </>}
                <NavLink to=".." className="btn btn-light">Zurück</NavLink>
            </div>
        </Col>
    </Row>
}
