import React, {FormEvent} from "react";
import {AxiosError} from "axios";
import {ApiClient} from "../api/ApiClient";
import {UpdateCredentialsDTO} from "../api/DTOs";
import {Alert, Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faKey} from "@fortawesome/free-solid-svg-icons";
import {ApiError} from "./ApiError";

type ChangePasswordFormProps = {
    onSuccess: () => void,
};

export const ChangePasswordForm: React.FunctionComponent<ChangePasswordFormProps> = ({onSuccess}) => {
    const [isDisabled, setDisabled] = React.useState<boolean>(false);
    const [error, setError] = React.useState<AxiosError | null>(null);
    const [errorStr, setErrorStr] = React.useState<string>("");

    const [oldPassword, setOldPassword] = React.useState<string>("");
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [newPassword2, setNewPassword2] = React.useState<string>("");

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (newPassword !== newPassword2) {
            setErrorStr("Die neuen Passwörter stimmen nicht überein");
            return;
        }

        setErrorStr("");
        setDisabled(true);

        ApiClient.changePassword(
            new UpdateCredentialsDTO(oldPassword, newPassword),
            onSuccess,
            (err) => {
                setError(err);
                setDisabled(false);
            }
        );
    };

    return <Form onSubmit={handleSubmit}>
        <div className="d-grid gap-3">
            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faKey}/>
                </InputGroup.Text>
                <Form.Control
                    type="password"
                    defaultValue={oldPassword}
                    required={true}
                    placeholder="Aktuelles Passwort"
                    disabled={isDisabled}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
            </InputGroup>

            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faKey}/>
                </InputGroup.Text>
                <Form.Control
                    type="password"
                    defaultValue={newPassword}
                    required={true}
                    placeholder="Neues Passwort"
                    disabled={isDisabled}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
            </InputGroup>

            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faKey}/>
                </InputGroup.Text>
                <Form.Control
                    type="password"
                    defaultValue={newPassword2}
                    required={true}
                    placeholder="Neues Passwort (wiederholen)"
                    disabled={isDisabled}
                    onChange={(e) => setNewPassword2(e.target.value)}
                />
            </InputGroup>

            <Button type="submit">
                <FontAwesomeIcon icon={faCheck}/> Passwort aktualisieren
            </Button>

            {errorStr && <Alert variant="warning">{errorStr}</Alert>}
        </div>
        <ApiError error={error} onClose={() => setError(null)}/>
    </Form>
}
