import React, {FormEvent} from "react";
import {AxiosError} from "axios";
import {ApiClient} from "../api/ApiClient";
import {SignupDTO} from "../api/DTOs";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faKey, faUser} from "@fortawesome/free-solid-svg-icons";
import {ApiError} from "./ApiError";

type SignupFormType = {
    secret: string,
    onSuccess: () => void,
};

export const SignupForm: React.FunctionComponent<SignupFormType> = ({secret, onSuccess}) => {
    const [isDisabled, setDisabled] = React.useState<boolean>(false);
    const [error, setError] = React.useState<AxiosError | null>(null);

    const [username, setUsername] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisabled(true);

        ApiClient.signup(
            new SignupDTO(username, password, firstName, lastName, secret),
            onSuccess,
            (err) => {
                setError(err);
                setDisabled(false);
            }
        )
    };

    return <Form onSubmit={handleSubmit}>
        <div className="d-grid gap-3">
            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser}/>
                </InputGroup.Text>
                <Form.Control
                    defaultValue={username}
                    required={true}
                    placeholder="Benutzername"
                    disabled={isDisabled}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </InputGroup>

            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faKey}/>
                </InputGroup.Text>
                <Form.Control
                    type="password"
                    defaultValue={password}
                    required={true}
                    placeholder="Passwort"
                    disabled={isDisabled}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </InputGroup>

            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser}/>
                </InputGroup.Text>
                <Form.Control
                    defaultValue={firstName}
                    required={true}
                    placeholder="Vornamen"
                    disabled={isDisabled}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </InputGroup>

            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser}/>
                </InputGroup.Text>
                <Form.Control
                    defaultValue={lastName}
                    required={true}
                    placeholder="Nachnamen"
                    disabled={isDisabled}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </InputGroup>

            <Button type="submit">
                <FontAwesomeIcon icon={faArrowRight}/> Registrieren
            </Button>
        </div>
        <ApiError error={error} onClose={() => setError(null)}/>
    </Form>
}
