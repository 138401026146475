import React, {FormEvent} from "react";
import {AxiosError} from "axios";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faEnvelope, faPhone, faUser} from "@fortawesome/free-solid-svg-icons";
import {ApiClient} from "../api/ApiClient";
import {VerificationRequestDTO} from "../api/DTOs";
import {ApiError} from "./ApiError";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type VerificationRequestProps = {
    type: "email" | "phone_number" | "username",
    setVerificationId: (verificationId: string) => void,
};

export const VerificationRequest: React.FunctionComponent<VerificationRequestProps> = ({type, setVerificationId}) => {
    const [content, setContent] = React.useState<string>("");
    const [isDisabled, setDisabled] = React.useState<boolean>(false);
    const [error, setError] = React.useState<AxiosError | null>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisabled(true);

        let data: VerificationRequestDTO;

        switch (type) {
            case "phone_number":
                data = { phone_number: content };
                break;
            case "email":
                data = { email: content };
                break;
            case "username":
                data = { username: content };
                break;
        }

        ApiClient.verificationRequest(
            data,
            (res) => {
                setVerificationId(res.verification);
            },
            (err) => {
                setError(err);
                setDisabled(false);
            }
        )
    };

    let formType: string;
    let formPlaceholder: string;
    let formIcon: IconProp;

    switch (type) {
        case "phone_number":
            formType = "text";
            formPlaceholder = "Deine Telefonnummer";
            formIcon = faPhone;
            break;
        case "email":
            formType = "email";
            formPlaceholder = "Deine Mailadresse";
            formIcon = faEnvelope;
            break;
        case "username":
            formType = "text";
            formPlaceholder = "Dein Benutzername";
            formIcon = faUser;
            break;
    }

    return <Form onSubmit={handleSubmit}>
        <div className="d-grid gap-3">
            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={formIcon}/>
                </InputGroup.Text>
                <Form.Control
                    type={formType}
                    defaultValue={content}
                    required={true}
                    placeholder={formPlaceholder}
                    disabled={isDisabled}
                    onChange={(e) => setContent(e.target.value)}
                />
            </InputGroup>
            <Button type="submit" disabled={isDisabled}>
                <FontAwesomeIcon icon={faArrowRight}/> Verifizieren
            </Button>
        </div>
        <ApiError error={error} onClose={() => setError(null)}/>
    </Form>
}
