import React, {FunctionComponent} from "react";
import {Col, Form, Row} from "react-bootstrap";

type HorizontalFormInputProps = {
    label: string,
    placeholder: string,
    defaultValue: string | number,
    onChange: (value: string) => void,
    isDisabled: boolean,
    isRequired: boolean,
};

export const HorizontalFormInput: FunctionComponent<HorizontalFormInputProps> = ({label, placeholder, defaultValue, onChange, isDisabled, isRequired, ...props}) => {
    return (
        <>
            <Form.Group as={Row} className="mb-3" controlId={label + "Input"}>
                <Form.Label column sm={4} className="text-start text-sm-end">
                    {label}
                </Form.Label>
                <Col sm={8}>
                    <Form.Control
                        defaultValue={defaultValue}
                        required={isRequired}
                        placeholder={placeholder}
                        disabled={isDisabled}
                        onChange={(e) => onChange(e.target.value)}
                        {...props}
                    />
                </Col>
            </Form.Group>
        </>
    );
}
