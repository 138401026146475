import React from 'react';
import {PrivateUserDTO} from "./api/DTOs";
import {AxiosError} from "axios";
import {ApiClient} from "./api/ApiClient";
import {UserContext} from "./context/UserContext";
import {Loading} from "./components/Loading";
import {ApiError} from "./components/ApiError";
import {Container, Navbar} from "react-bootstrap";
import {NavLink, Route, Routes} from "react-router-dom";
import {NotFound} from "./pages/NotFound";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRightFromBracket,
    faArrowRightToBracket,
    faCalendarDays, faEnvelope,
    faGraduationCap,
    faLocationDot, faUserPlus, faWrench
} from "@fortawesome/free-solid-svg-icons";
import {LoginPage} from "./pages/LoginPage";
import {SignupPage} from "./pages/SignupPage";
import {IndexPage} from "./pages/IndexPage";
import {LogoutPage} from "./pages/LogoutPage";
import {SettingsPage} from "./pages/settings/SettingsPage";
import {ChangePasswordPage} from "./pages/settings/ChangePasswordPage";
import {ChangeEmailAddressPage} from "./pages/settings/ChangeEmailAddressPage";
import {ChangePhoneNumberPage} from "./pages/settings/ChangePhoneNumberPage";
import {ChangeUserDataPage} from "./pages/settings/ChangeUserDataPage";

type NavbarItemProps = {
    text: string,
    target: string,
    icon: IconProp,
};

const NavbarItem: React.FunctionComponent<NavbarItemProps> = ({text, target, icon}) => {
    return <li className="nav-item">
        <NavLink to={target} className="nav-link">
            {icon && <><FontAwesomeIcon icon={icon} fixedWidth/>&nbsp;</>}
            {text}
        </NavLink>
    </li>
}

export const App: React.FunctionComponent = () => {
    const [isInitialized, setInitialized] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<PrivateUserDTO | null>(null);
    const [error, setError] = React.useState<AxiosError | null>(null);

    // Initialization --> Who am I?
    React.useEffect(() => {
        if (isInitialized) return;

        ApiClient.getMe((res) => {
            setUser(res);
            setInitialized(true);
        }, (err) => {
            setInitialized(true);

            // If error is 403 --> We are logged out --> Keep user at null and do not report as error
            if (err.response && err.response.status === 403) {
                return;
            }

            setError(err);
        });
    }, [isInitialized]);

    if (!isInitialized) {
        return <Loading show={true}/>
    }

    if (error) {
        return <ApiError error={error}/>
    }

    return (
        <>
            <UserContext.Provider value={{user, setUser}}>
                <div className="bg-dark text-light py-2">
                    <Container className="d-flex justify-content-between">
                        <div>
                            🇨🇭 Deutsch
                        </div>
                        <div>
                            <a href="mailto:info@tc-academia.ch" className="text-light text-decoration-none">
                                <FontAwesomeIcon icon={faEnvelope}/> info@tc-academia.ch
                            </a>
                            &emsp;
                            Telegram: @tcacademia | Newsletter
                        </div>
                    </Container>
                </div>
                <Navbar bg="primary" variant="dark" expand="sm">
                    <Container>
                        <NavLink to="/" className="navbar-brand">
                            Tanzportal
                        </NavLink>
                        <Navbar.Toggle aria-controls="navbar-collapse" data-bs-toggle="collapse"
                                       data-bs-target="#navbarScroll"/>
                        <div className="collapse navbar-collapse" id="navbarScroll">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {/* TODO: Navbar elements go here */}
                                <NavbarItem target="/courses" text="Kurse" icon={faGraduationCap}/>
                                <NavbarItem target="/events" text="Events" icon={faCalendarDays}/>
                                <NavbarItem target="/locations" text="Locations" icon={faLocationDot}/>

                                {/* Show when logged in */}
                                {user && <NavbarItem target="/settings" text="Einstellungen" icon={faWrench}/>}
                                {user && <NavbarItem target="/logout" text="Logout" icon={faArrowRightFromBracket}/>}

                                {/* Show when anonymous */}
                                {!user && <NavbarItem target="/login" text="Login" icon={faArrowRightToBracket}/>}
                                {!user && <NavbarItem target="/signup" text="Signup" icon={faUserPlus}/>}
                                {!user && <NavbarItem target="/reset-pw" text="Password Reset" icon={faUserPlus}/>}

                            </ul>
                            {user &&
                                <div className="text-light">
                                    Angemeldet als {user.first_name} {user.last_name}
                                </div>
                            }
                        </div>
                    </Container>
                </Navbar>
                <Container className="my-5 pt-3">
                    <Routes>
                        {/* TODO: Routes go here */}
                        <Route path="login" element={<LoginPage/>}/>
                        <Route path="logout" element={<LogoutPage/>}/>
                        <Route path="signup" element={<SignupPage/>}/>

                        <Route path="settings">
                            <Route index element={<SettingsPage/>}/>
                            <Route path="change-password" element={<ChangePasswordPage/>}/>
                            <Route path="change-email-address" element={<ChangeEmailAddressPage/>}/>
                            <Route path="change-phone-number" element={<ChangePhoneNumberPage/>}/>
                            <Route path="edit" element={<ChangeUserDataPage/>}/>
                        </Route>

                        <Route index element={<IndexPage/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </Container>
            </UserContext.Provider>
        </>
    );
}
