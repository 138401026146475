// NECESSARY AUXILIARY TYPES

export type Language = "de" | "en";
export type CourseType = "AL" | "CO" | "WS" | "HP" | "GT" | "FT" | "SE";
export type CourseStyle = "AL" | "SD" | "ST" | "LA" | "RH" | "DF" | "EW" | "TG" | "VW" | "SF" | "QS" | "SB" | "CC" | "RB" | "PD" | "JI" | "OT";
export type CourseLevel = "ALL" | "BEB" | "BEA" | "IMB" | "IMA" | "INB" | "INA" | "ADB" | "ADA" | "SUP";

// DEFINED SCHEMAS

export class ChangeablePublicUserDTO {
    readonly username: string;
    readonly first_name: string;
    readonly last_name: string;
    readonly title: string | null;
    readonly gender: string | null;
    readonly birthday: string | null;

    constructor(username: string, first_name: string, last_name: string, title: string | null, gender: string | null, birthday: string | null) {
        this.username = username;
        this.first_name = first_name;
        this.last_name = last_name;
        this.title = title;
        this.gender = gender;
        this.birthday = birthday;
    }
}

export class ChangeablePrivateUserDTO extends ChangeablePublicUserDTO {
    readonly address_street_1: string | null;
    readonly address_street_2: string | null;
    readonly address_zip_code: string | null;
    readonly address_town: string | null;
    readonly address_country: string | null;

    constructor(username: string, first_name: string, last_name: string, title: string | null, gender: string | null, birthday: string | null, address_street_1: string | null, address_street_2: string | null, address_zip_code: string | null, address_town: string | null, address_country: string | null) {
        super(username, first_name, last_name, title, gender, birthday);
        this.address_street_1 = address_street_1;
        this.address_street_2 = address_street_2;
        this.address_zip_code = address_zip_code;
        this.address_town = address_town;
        this.address_country = address_country;
    }
}

export class PublicUserDTO extends ChangeablePublicUserDTO {
    readonly id: string;
    readonly profile_picture: string | null;
    readonly date_joined: string;
    readonly last_login: string | null;
    readonly last_activity: string | null;
    readonly is_staff: boolean;

    constructor(id: string, username: string, first_name: string, last_name: string, title: string | null, gender: string | null, birthday: string | null, profile_picture: string | null, date_joined: string, last_login: string | null, last_activity: string | null, is_staff: boolean) {
        super(username, first_name, last_name, title, gender, birthday);
        this.id = id;
        this.profile_picture = profile_picture;
        this.date_joined = date_joined;
        this.last_login = last_login;
        this.last_activity = last_activity;
        this.is_staff = is_staff;
    }
}

export class PrivateUserDTO extends PublicUserDTO {
    readonly address_street_1: string | null;
    readonly address_street_2: string | null;
    readonly address_zip_code: string | null;
    readonly address_town: string | null;
    readonly address_country: string | null;
    readonly email: string;
    readonly phone_number: string | null;
    readonly legi_number: string | null;
    readonly legi_expiration: string | null;

    constructor(id: string, username: string, first_name: string, last_name: string, title: string | null, gender: string | null, birthday: string | null, profile_picture: string | null, date_joined: string, last_login: string | null, last_activity: string | null, is_staff: boolean, address_street_1: string | null, address_street_2: string | null, address_zip_code: string | null, address_town: string | null, address_country: string | null, email: string, phone_number: string | null, legi_number: string | null, legi_expiration: string | null) {
        super(id, username, first_name, last_name, title, gender, birthday, profile_picture, date_joined, last_login, last_activity, is_staff);
        this.address_street_1 = address_street_1;
        this.address_street_2 = address_street_2;
        this.address_zip_code = address_zip_code;
        this.address_town = address_town;
        this.address_country = address_country;
        this.email = email;
        this.phone_number = phone_number;
        this.legi_number = legi_number;
        this.legi_expiration = legi_expiration;
    }
}

export class ChangeableLocation {
    readonly primary_name: string;
    readonly secondary_name: string;
    readonly address_street_1: string;
    readonly address_street_2: string;
    readonly address_zip_code: string;
    readonly address_town: string;
    readonly address_country: string;
    readonly google_maps_code: string;
    readonly is_active: string;
    readonly description_de: string;
    readonly description_en: string;
    readonly how_to_find_de: string;
    readonly how_to_find_en: string;

    constructor(primary_name: string, secondary_name: string, address_street_1: string, address_street_2: string, address_zip_code: string, address_town: string, address_country: string, google_maps_code: string, is_active: string, description_de: string, description_en: string, how_to_find_de: string, how_to_find_en: string) {
        this.primary_name = primary_name;
        this.secondary_name = secondary_name;
        this.address_street_1 = address_street_1;
        this.address_street_2 = address_street_2;
        this.address_zip_code = address_zip_code;
        this.address_town = address_town;
        this.address_country = address_country;
        this.google_maps_code = google_maps_code;
        this.is_active = is_active;
        this.description_de = description_de;
        this.description_en = description_en;
        this.how_to_find_de = how_to_find_de;
        this.how_to_find_en = how_to_find_en;
    }
}

export class Location extends ChangeableLocation {
    readonly id: string;

    constructor(id: string, primary_name: string, secondary_name: string, address_street_1: string, address_street_2: string, address_zip_code: string, address_town: string, address_country: string, google_maps_code: string, is_active: string, description_de: string, description_en: string, how_to_find_de: string, how_to_find_en: string) {
        super(primary_name, secondary_name, address_street_1, address_street_2, address_zip_code, address_town, address_country, google_maps_code, is_active, description_de, description_en, how_to_find_de, how_to_find_en);
        this.id = id;
    }
}

class CommonCourse {
    internal_id: string;
    title_de: string;
    title_en: string;
    subtitle_de: string;
    subtitle_en: string;
    comment_de: string;
    comment_en: string;
    base_price: number;
    is_visible: boolean;
    is_featured: boolean;
    language: Language;
    date_start: string;
    date_end: string;
    weekday: string;
    type: CourseType;
    style: CourseStyle;
    level: CourseLevel;

    constructor(internal_id: string, title_de: string, title_en: string, subtitle_de: string, subtitle_en: string, comment_de: string, comment_en: string, base_price: number, is_visible: boolean, is_featured: boolean, language: Language, date_start: string, date_end: string, weekday: string, type: CourseType, style: CourseStyle, level: CourseLevel) {
        this.internal_id = internal_id;
        this.title_de = title_de;
        this.title_en = title_en;
        this.subtitle_de = subtitle_de;
        this.subtitle_en = subtitle_en;
        this.comment_de = comment_de;
        this.comment_en = comment_en;
        this.base_price = base_price;
        this.is_visible = is_visible;
        this.is_featured = is_featured;
        this.language = language;
        this.date_start = date_start;
        this.date_end = date_end;
        this.weekday = weekday;
        this.type = type;
        this.style = style;
        this.level = level;
    }
}

export class ChangeableCourse extends CommonCourse {
    readonly location_id: string;

    constructor(internal_id: string, title_de: string, title_en: string, subtitle_de: string, subtitle_en: string, comment_de: string, comment_en: string, base_price: number, is_visible: boolean, is_featured: boolean, language: Language, date_start: string, date_end: string, weekday: string, type: CourseType, style: CourseStyle, level: CourseLevel, location_id: string) {
        super(internal_id, title_de, title_en, subtitle_de, subtitle_en, comment_de, comment_en, base_price, is_visible, is_featured, language, date_start, date_end, weekday, type, style, level);
        this.location_id = location_id;
    }
}

export class Course extends CommonCourse {
    readonly id: string;
    readonly location: Location;

    constructor(id: string, internal_id: string, title_de: string, title_en: string, subtitle_de: string, subtitle_en: string, comment_de: string, comment_en: string, base_price: number, is_visible: boolean, is_featured: boolean, language: Language, date_start: string, date_end: string, weekday: string, type: CourseType, style: CourseStyle, level: CourseLevel, location: Location) {
        super(internal_id, title_de, title_en, subtitle_de, subtitle_en, comment_de, comment_en, base_price, is_visible, is_featured, language, date_start, date_end, weekday, type, style, level);
        this.id = id;
        this.location = location;
    }
}

// OTHER AUXILIARY DTOs

export class CredentialsDTO {
    readonly username: string;
    readonly password: string;

    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
    }
}

type HAS_PHONE_NUMBER = { readonly phone_number: string };
type HAS_EMAIL = { readonly email: string };
type HAS_USERNAME = { readonly username: string };
export type VerificationRequestDTO = HAS_PHONE_NUMBER | HAS_EMAIL | HAS_USERNAME;

export class UpdateCredentialsDTO {
    readonly old_password: string;
    readonly new_password: string;

    constructor(old_password: string, new_password: string) {
        this.old_password = old_password;
        this.new_password = new_password;
    }
}

export class VerificationDTO {
    readonly verification: string;

    constructor(verification: string) {
        this.verification = verification;
    }
}

export class VerificationTokenDTO extends VerificationDTO {
    readonly token: number;

    constructor(verification: string, token: number) {
        super(verification);
        this.token = token;
    }

}

export class SecretDTO {
    readonly secret: string;

    constructor(secret: string) {
        this.secret = secret;
    }
}

export class ResetPasswordDTO extends SecretDTO {
    readonly password: string;

    constructor(password: string, secret: string) {
        super(secret);
        this.password = password;
    }

}

export class SignupDTO extends ResetPasswordDTO {
    readonly username: string;
    readonly first_name: string;
    readonly last_name: string;

    constructor(username: string, password: string, first_name: string, last_name: string, secret: string) {
        super(password, secret);
        this.username = username;
        this.first_name = first_name;
        this.last_name = last_name;
    }
}
