import React from "react";
import {UserContext} from "../../context/UserContext";
import {Alert, Col, ProgressBar, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {VerificationConfirm} from "../../components/VerificationConfirm";
import {VerificationRequest} from "../../components/VerificationRequest";
import {ApiClient} from "../../api/ApiClient";
import {SecretDTO} from "../../api/DTOs";
import {Loading} from "../../components/Loading";
import {AxiosError} from "axios";
import {ApiError} from "../../components/ApiError";

export const ChangePhoneNumberPage: React.FunctionComponent = () => {
    const [verificationId, setVerificationId] = React.useState<string>("");
    const [secretId, setSecretId] = React.useState<string>("");
    const [isFinished, setFinished] = React.useState<boolean>(false);
    const [error, setError] = React.useState<AxiosError | null>(null);

    const {setUser} = React.useContext(UserContext);

    React.useEffect(() => {
        if (!secretId) return;

        ApiClient.changePhoneNumber(
            new SecretDTO(secretId),
            (res) => {
                setUser(res);
                setFinished(true);
            },
            (err) => {
                setError(err);
            }
        )
    }, [secretId, setUser]);

    let child: React.JSX.Element;
    let progress: number;

    if (isFinished) {
        child = <Alert variant="success">Deine Telefonnummer wurde erfolgreich aktualkisiert</Alert>
        progress = 2;
    } else if (secretId) {
        child = <Loading show={true}/>
        progress = 1;
    } else if (verificationId) {
        child = <VerificationConfirm verificationId={verificationId} setSecretId={setSecretId}/>
        progress = 1;
    } else {
        child = <VerificationRequest type="phone_number" setVerificationId={setVerificationId}/>
        progress = 0;
    }

    const numSteps: number = 2;
    const progressLabel: string = isFinished ? "Fertig" : "Schritt " + (progress + 1) + " von " + numSteps;
    const progressValue: number = isFinished ? 100 : 100 * (progress + 1) / numSteps;
    const progressVariant: string = isFinished ? "success" : "primary";

    return <Row>
        <Col sm={{span: 4, offset: 4}}>
            <h1 className="text-center mb-3">Telefonnummer ändern</h1>
            <div className="d-grid gap-3">
                <ProgressBar
                    animated={!isFinished}
                    now={progressValue}
                    label={progressLabel}
                    variant={progressVariant}
                />
                {child}
                <NavLink to=".." className="btn btn-light">
                    Zurück
                </NavLink>
            </div>
            <ApiError error={error} onClose={() => setError(null)}/>
        </Col>
    </Row>
}
