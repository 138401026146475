import React from "react";
import {Alert, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonDigging} from "@fortawesome/free-solid-svg-icons";

export const IndexPage: React.FunctionComponent = () => {
    return <>
        <h1>Tanzportal</h1>
        <p className="lead">Noch ist hier nicht viel zu sehen</p>
        <Alert variant="warning">
            <p className="lead">
                <FontAwesomeIcon icon={faPersonDigging}/>
                &emsp;
                Baustellenbereich
                &emsp;
                <FontAwesomeIcon icon={faPersonDigging}/>
            </p>
            In der nächsten Zeit entsteht hier das neue Tanzportal des TC Academia Zürich
        </Alert>
        <Button href="https://tc-academia.ch/de/" target="_blank">Zur Vereinsseite</Button>
    </>
}
