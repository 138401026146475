import {API_BASE_URL} from "../config";
import axios, {AxiosError, AxiosResponse} from "axios";
import {
    ChangeableCourse,
    ChangeableLocation,
    ChangeablePrivateUserDTO, Course,
    CredentialsDTO, Location,
    PrivateUserDTO, PublicUserDTO,
    ResetPasswordDTO,
    SecretDTO,
    SignupDTO,
    UpdateCredentialsDTO, VerificationDTO, VerificationRequestDTO, VerificationTokenDTO
} from "./DTOs";

export class ApiClient {
    private static readonly baseURL: string = API_BASE_URL;

    // USER MANAGEMENT ENDPOINTS

    public static login(credentials: CredentialsDTO, handleResponse: (response: PrivateUserDTO) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().post<null, CredentialsDTO, PrivateUserDTO>(
            "users/login",
            null,
            credentials,
            handleResponse,
            handleError
        );
    }

    public static logout(handleResponse: () => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().post<null, null, null>(
            "users/logout",
            null,
            null,
            handleResponse,
            handleError
        );
    }

    public static changePassword(data: UpdateCredentialsDTO, handleResponse: () => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().post<null, UpdateCredentialsDTO, null>(
            "users/change-password",
            null,
            data,
            handleResponse,
            handleError
        );
    }

    public static changeProfilePicture(profilePicture: File, handleResponse: (response: PrivateUserDTO) => void, handleError: (error: AxiosError) => void): void {
        const fd = new FormData();
        fd.append("profile-picture", profilePicture);

        new ApiClient().post<null, FormData, PrivateUserDTO>(
            "users/change-profile-picture",
            null,
            fd,
            handleResponse,
            handleError
        );
    }

    public static deleteProfilePicture(handleResponse: (response: PrivateUserDTO) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().delete<null, PrivateUserDTO>(
            "users/change-profile-picture",
            null,
            handleResponse,
            handleError
        );
    }

    public static changeEmailAddress(data: SecretDTO, handleResponse: (response: PrivateUserDTO) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().post<null, SecretDTO, PrivateUserDTO>(
            "users/change-email-address",
            null,
            data,
            handleResponse,
            handleError
        );
    }

    public static changePhoneNumber(data: SecretDTO, handleResponse: (response: PrivateUserDTO) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().post<null, SecretDTO, PrivateUserDTO>(
            "users/change-phone-number",
            null,
            data,
            handleResponse,
            handleError
        );
    }

    public static signup(data: SignupDTO, handleResponse: () => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().post<null, SignupDTO, null>(
            "users/signup",
            null,
            data,
            handleResponse,
            handleError
        );
    }

    public static resetPassword(data: ResetPasswordDTO, handleResponse: () => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().post<null, ResetPasswordDTO, null>(
            "users/reset-password",
            null,
            data,
            handleResponse,
            handleError
        );
    }

    // USER DATA ENDPOINTS

    public static getMe(handleResponse: (response: PrivateUserDTO) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().get<null, PrivateUserDTO>(
            "users/me",
            null,
            handleResponse,
            handleError
        );
    }

    public static updateMe(data: ChangeablePrivateUserDTO, handleResponse: (response: PrivateUserDTO) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().patch<null, ChangeablePrivateUserDTO, PrivateUserDTO>(
            "users/me",
            null,
            data,
            handleResponse,
            handleError
        );
    }

    public static getUserById(userId: string, handleResponse: (response: PublicUserDTO) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().get<null, PublicUserDTO>(
            "users/" + userId,
            null,
            handleResponse,
            handleError
        );
    }

    // VERIFICATION ENDPOINTS

    public static verificationRequest(data: VerificationRequestDTO, handleResponse: (response: VerificationDTO) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().post<null, VerificationRequestDTO, VerificationDTO>(
            "verification/request",
            null,
            data,
            handleResponse,
            handleError
        );
    }

    public static verificationConfirm(data: VerificationTokenDTO, handleResponse: (response: SecretDTO) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().post<null, VerificationTokenDTO, SecretDTO>(
            "verification/confirm",
            null,
            data,
            handleResponse,
            handleError
        );
    }

    // LOCATION DATA ENDPOINTS

    public static createLocation(data: ChangeableLocation, handleResponse: (response: Location) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().post<null, ChangeableLocation, Location>(
            "locations/create",
            null,
            data,
            handleResponse,
            handleError
        );
    }

    public static getLocations(handleResponse: (response: Location[]) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().get<null, Location[]>(
            "locations/all",
            null,
            handleResponse,
            handleError
        );
    }

    public static getActiveLocations(handleResponse: (response: Location[]) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().get<null, Location[]>(
            "locations/active",
            null,
            handleResponse,
            handleError
        );
    }

    public static getLocationById(locationId: string, handleResponse: (response: Location) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().get<null, Location>(
            "locations/" + locationId,
            null,
            handleResponse,
            handleError
        );
    }

    public static updateLocationById(locationId: string, data: ChangeableLocation, handleResponse: (response: Location) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().patch<null, ChangeableLocation, Location>(
            "locations/" + locationId,
            null,
            data,
            handleResponse,
            handleError
        );
    }

    public static deleteLocationById(locationId: string, handleResponse: () => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().delete<null, null>(
            "locations/" + locationId,
            null,
            handleResponse,
            handleError
        );
    }

    // COURSE ENDPOINTS

    public static createCourse(data: ChangeableCourse, handleResponse: (response: Course) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().post<null, ChangeableCourse, Course>(
            "courses/create",
            null,
            data,
            handleResponse,
            handleError
        );
    }

    public static getCourses(handleResponse: (response: Course[]) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().get<null, Course[]>(
            "courses/all",
            null,
            handleResponse,
            handleError
        );
    }

    public static getVisibleCourses(handleResponse: (response: Course[]) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().get<null, Course[]>(
            "courses/visible",
            null,
            handleResponse,
            handleError
        );
    }

    public static getFeaturedCourses(handleResponse: (response: Course[]) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().get<null, Course[]>(
            "courses/featured",
            null,
            handleResponse,
            handleError
        );
    }

    public static getCourseById(courseId: string, handleResponse: (response: Course) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().get<null, Course>(
            "courses/" + courseId,
            null,
            handleResponse,
            handleError
        );
    }

    public static updateCourseById(courseId: string, data: ChangeableCourse, handleResponse: (response: Course) => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().patch<null, ChangeableCourse, Course>(
            "courses/" + courseId,
            null,
            data,
            handleResponse,
            handleError
        );
    }

    public static deleteCourseById(courseId: string, handleResponse: () => void, handleError: (error: AxiosError) => void): void {
        new ApiClient().delete<null, null>(
            "courses/" + courseId,
            null,
            handleResponse,
            handleError
        );
    }

    // GENERIC API CALLS

    private request<P, D, R>(method: string, url: string, queryParams: P, data: D, handleResponse: (response: R) => void, handleError: (error: AxiosError) => void): void {
        axios({
            url: url,
            method: method,
            baseURL: ApiClient.baseURL,
            params: queryParams,
            data: data,
            withCredentials: true,
        })
            .then((response: AxiosResponse<R, D>) => handleResponse(response.data))
            .catch(handleError)
    }

    private get<P, R>(url: string, queryParams: P, handleResponse: (response: R) => void, handleError: (error: AxiosError) => void): void {
        this.request<P, null, R>("get", url, queryParams, null, handleResponse, handleError);
    }

    private post<P, D, R>(url: string, queryParams: P, data: D, handleResponse: (response: R) => void, handleError: (error: AxiosError) => void): void {
        this.request<P, D, R>("post", url, queryParams, data, handleResponse, handleError);
    }

    private patch<P, D, R>(url: string, queryParams: P, data: D, handleResponse: (response: R) => void, handleError: (error: AxiosError) => void): void {
        this.request<P, D, R>("patch", url, queryParams, data, handleResponse, handleError);
    }

    private delete<P, R>(url: string, queryParams: P, handleResponse: (response: R) => void, handleError: (error: AxiosError) => void): void {
        this.request<P, null, R>("delete", url, queryParams, null, handleResponse, handleError);
    }
}
