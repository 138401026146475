import React from "react";
import {UserContext} from "../../context/UserContext";
import {Navigate, NavLink} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faKey, faPen, faPhone} from "@fortawesome/free-solid-svg-icons";

type PersonalDataRowProps = {
    attribute: string,
    value: string,
};

const PersonalDataRow: React.FunctionComponent<PersonalDataRowProps> = ({attribute, value}) => {
    const v: React.JSX.Element = value ? <>{value}</> : <i>Keine Angabe</i>;

    return <Row>
        <Col sm={4} xs={6} className="text-end"><strong>{attribute}</strong></Col>
        <Col sm={8} xs={6}>{v}</Col>
    </Row>
}

export const SettingsPage: React.FunctionComponent = () => {
    const {user, /*setUser*/} = React.useContext(UserContext);

    if (!user) {
        return <Navigate to="/"/>
    }

    // Gender
    let gender: string;

    switch (user.gender) {
        case "m":
            gender = "Männlich";
            break;
        case "f":
            gender = "Weiblich";
            break;
        case "d":
            gender = "Divers";
            break;
        default:
            gender = "";
    }

    // Date formatting
    const dateJoined: string = user.date_joined ? new Date(user.date_joined).toLocaleString() : "";
    const lastLogin: string = user.last_login ? new Date(user.last_login).toLocaleString() : "";
    const lastActivity: string = user.last_activity ? new Date(user.last_activity).toLocaleString() : "";

    // Address formatting
    // TODO: Implement address formatting
    let address = "";

    // Assemble output
    return <>
        <h1>Einstellungen</h1>
        <Row>
            <Col sm={6}>
                <h2>Persönliche Daten</h2>
                <PersonalDataRow attribute="Benutzername" value={user.username}/>
                <PersonalDataRow attribute="Vorname" value={user.first_name}/>
                <PersonalDataRow attribute="Nachname" value={user.last_name}/>
                <PersonalDataRow attribute="Titel" value={user.title ?? ""}/>
                <PersonalDataRow attribute="Geschlecht" value={gender}/>
                <PersonalDataRow attribute="Geburtstag" value={user.birthday ?? ""}/>

                <hr/>

                <h2>Kontaktdaten</h2>
                <PersonalDataRow attribute="Mailadresse" value={user.email}/>
                <PersonalDataRow attribute="Telefonnummer" value={user.phone_number ?? ""}/>
                <PersonalDataRow attribute="Adresse" value={address}/>

                <hr/>

                <h2>Weitere Daten</h2>
                <PersonalDataRow attribute="Mitglied seit" value={dateJoined}/>
                <PersonalDataRow attribute="Letzter Login" value={lastLogin}/>
                <PersonalDataRow attribute="Letzte Aktivität" value={lastActivity}/>
                <PersonalDataRow attribute="Mitarbeiter" value={user.is_staff ? "Ja" : "Nein"}/>
            </Col>
            <Col sm={6}>
                <h2>Aktionen</h2>
                <div className="d-grid gap-3">
                    <NavLink className="btn btn-primary" to="edit">
                        <FontAwesomeIcon icon={faPen}/> Daten bearbeiten
                    </NavLink>
                    <NavLink className="btn btn-success" to="change-email-address">
                        <FontAwesomeIcon icon={faEnvelope}/> Mailadresse ändern
                    </NavLink>
                    <NavLink className="btn btn-warning" to="change-phone-number">
                        <FontAwesomeIcon icon={faPhone}/> Telefonnummer ändern
                    </NavLink>
                    <NavLink className="btn btn-danger" to="change-password">
                        <FontAwesomeIcon icon={faKey}/> Passwort ändern
                    </NavLink>
                </div>
            </Col>
        </Row>
    </>
}
