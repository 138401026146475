import React, {FormEvent} from "react";
import {AxiosError} from "axios";
import {UserContext} from "../context/UserContext";
import {Navigate, useNavigate} from "react-router-dom";
import {ApiClient} from "../api/ApiClient";
import {CredentialsDTO} from "../api/DTOs";
import {Alert, Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightToBracket, faKey, faUser} from "@fortawesome/free-solid-svg-icons";
import {ApiError} from "../components/ApiError";

export const LoginPage: React.FunctionComponent = () => {
    const [error, setError] = React.useState<AxiosError | null>(null);
    const [errorStr, setErrorStr] = React.useState<string>("");
    const [isDisabled, setDisabled] = React.useState<boolean>(false);

    const {user, setUser} = React.useContext(UserContext);
    const navigate = useNavigate();

    if (user) {
        return <Navigate to="/"/>
    }

    const onLoginAttempt = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisabled(true);
        setErrorStr("");

        const target = e.target as typeof e.target & {
            username: { value: string },
            password: { value: string },
        };

        ApiClient.login(
            new CredentialsDTO(
                target.username.value,
                target.password.value
            ), (res) => {
                setUser(res);
            }, (err) => {
                if (err.response && err.response.status === 403) {
                    const errData = err.response.data as { message: string };
                    setErrorStr(errData.message);
                } else {
                    setError(err);
                }

                setDisabled(false);
            }
        );
    };

    return (
        <>
            <Row>
                <Col sm={{span: 4, offset: 4}}>
                    <h1 className="text-center my-3">Tanzportal Login</h1>
                    <Form onSubmit={onLoginAttempt}>
                        <div className="d-grid gap-3">
                            <InputGroup>
                                <InputGroup.Text id="usernameIcon">
                                    <FontAwesomeIcon icon={faUser}/>
                                </InputGroup.Text>
                                <Form.Control
                                    name="username"
                                    required={true}
                                    placeholder="Benutzername"
                                    disabled={isDisabled}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text id="passwordIcon">
                                    <FontAwesomeIcon icon={faKey}/>
                                </InputGroup.Text>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    required={true}
                                    placeholder="Passwort"
                                    disabled={isDisabled}
                                />
                            </InputGroup>
                            <Button variant="primary" disabled={isDisabled} type="submit">
                                <FontAwesomeIcon icon={faArrowRightToBracket}/> Login
                            </Button>
                            <Button variant="light" disabled={isDisabled} onClick={() => navigate("/reset-pw")}>
                                Passwort vergessen
                            </Button>
                            <Button variant="light" disabled={isDisabled} onClick={() => navigate("/signup")}>
                                Registrieren
                            </Button>
                            {errorStr && <Alert variant="warning">{errorStr}</Alert>}
                        </div>
                    </Form>
                </Col>
            </Row>
            <ApiError error={error} onClose={() => setError(null)} />
        </>
    );
}
