import React from "react";
import {PrivateUserDTO} from "../api/DTOs";

type UserContextType = {
    user: PrivateUserDTO | null,
    setUser: React.Dispatch<React.SetStateAction<PrivateUserDTO | null>>,
};

export const UserContext = React.createContext<UserContextType>({
    user: null,
    setUser: () => {},
});
