import React, {FormEvent} from "react";
import {ChangeablePrivateUserDTO, PrivateUserDTO} from "../api/DTOs";
import {Button, Col, Form, Row} from "react-bootstrap";
import {ApiClient} from "../api/ApiClient";
import {AxiosError} from "axios";
import {HorizontalFormInput} from "./HorizontalFormInput";
import {ApiError} from "./ApiError";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk, faReply} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";

type ChangeUserDataFormProps = {
    user: PrivateUserDTO,
    setUser: (u: PrivateUserDTO) => void,
};

export const ChangeUserDataForm: React.FunctionComponent<ChangeUserDataFormProps> = ({user, setUser}) => {
    const [isDisabled, setDisabled] = React.useState<boolean>(false);
    const [error, setError] = React.useState<AxiosError | null>(null);

    const [username, setUsername] = React.useState<string>(user.username);
    const [firstName, setFirstName] = React.useState<string>(user.first_name);
    const [lastName, setLastName] = React.useState<string>(user.last_name);
    const [title, setTitle] = React.useState<string | null>(user.title);
    const [gender, setGender] = React.useState<string | null>(user.gender);
    const [birthday, setBirthday] = React.useState<string | null>(user.birthday);
    const [addressStreet1, setAddressStreet1] = React.useState<string | null>(user.address_street_1);
    const [addressStreet2, setAddressStreet2] = React.useState<string | null>(user.address_street_2);
    const [addressZipCode, setAddressZipCode] = React.useState<string | null>(user.address_zip_code);
    const [addressTown, setAddressTown] = React.useState<string | null>(user.address_town);
    const [addressCountry, setAddressCountry] = React.useState<string | null>(user.address_country);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisabled(true);

        ApiClient.updateMe(
            new ChangeablePrivateUserDTO(
                username,
                firstName,
                lastName,
                title ? title : null,
                gender ? gender : null,
                birthday ? birthday : null,
                addressStreet1 ? addressStreet1 : null,
                addressStreet2 ? addressStreet2 : null,
                addressZipCode ? addressZipCode : null,
                addressTown ? addressTown : null,
                addressCountry ? addressCountry : null
            ),
            (res) => {
                setUser(res);
            },
            (err) => {
                setDisabled(false);
                setError(err);
            }
        )
    };

    return <Form onSubmit={handleSubmit}>
        <Row>
            <Col sm={6}>
                <h2>Persönliche Angaben</h2>
                <HorizontalFormInput
                    label="Benutzername"
                    placeholder="Benutzername"
                    defaultValue={username}
                    onChange={setUsername}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <HorizontalFormInput
                    label="Vorname"
                    placeholder="Vorname"
                    defaultValue={firstName}
                    onChange={setFirstName}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <HorizontalFormInput
                    label="Nachname"
                    placeholder="Nachname"
                    defaultValue={lastName}
                    onChange={setLastName}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4} className="text-start text-sm-end">
                        Titel
                    </Form.Label>
                    <Col sm={8}>
                        <Form.Select defaultValue={title ?? ""} onChange={(e) => setTitle(e.target.value)}>
                            <option value="">Keine Angabe</option>
                            <option value="Dr.">Dr.</option>
                            <option value="Prof.">Prof.</option>
                            <option value="Prof. Dr.">Prof. Dr.</option>
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4} className="text-start text-sm-end">
                        Geschlecht
                    </Form.Label>
                    <Col sm={8}>
                        <Form.Select defaultValue={gender ?? ""} onChange={(e) => setGender(e.target.value)}>
                            <option value="">Keine Angabe</option>
                            <option value="m">Männlich</option>
                            <option value="f">Weiblich</option>
                            <option value="d">Divers</option>
                        </Form.Select>
                    </Col>
                </Form.Group>

                <HorizontalFormInput
                    label="Geburtstag"
                    placeholder="Geburtstag"
                    defaultValue={birthday ?? ""}
                    onChange={setBirthday}
                    isDisabled={isDisabled}
                    isRequired={false}
                    {...{type: "date"}}
                />

            </Col>
            <Col sm={6}>
                <h2>Kontaktangaben</h2>
                <HorizontalFormInput
                    label="Adresszeile 1"
                    placeholder="Adresszeile 1"
                    defaultValue={addressStreet1 ?? ""}
                    onChange={setAddressStreet1}
                    isDisabled={isDisabled}
                    isRequired={false}
                />
                <HorizontalFormInput
                    label="Adresszeile 2"
                    placeholder="Adresszeile 2"
                    defaultValue={addressStreet2 ?? ""}
                    onChange={setAddressStreet2}
                    isDisabled={isDisabled}
                    isRequired={false}
                />
                <HorizontalFormInput
                    label="Postleitzahl"
                    placeholder="Postleitzahl"
                    defaultValue={addressZipCode ?? ""}
                    onChange={setAddressZipCode}
                    isDisabled={isDisabled}
                    isRequired={false}
                />
                <HorizontalFormInput
                    label="Ortschaft"
                    placeholder="Ortschaft"
                    defaultValue={addressTown ?? ""}
                    onChange={setAddressTown}
                    isDisabled={isDisabled}
                    isRequired={false}
                />
                <HorizontalFormInput
                    label="Land"
                    placeholder="Land"
                    defaultValue={addressCountry ?? ""}
                    onChange={setAddressCountry}
                    isDisabled={isDisabled}
                    isRequired={false}
                />

                <div className="text-end">
                    <NavLink to=".." className="btn btn-light">
                        <FontAwesomeIcon icon={faReply}/> Abbrechen
                    </NavLink>
                    &emsp;
                    <Button type="submit" variant="primary">
                        <FontAwesomeIcon icon={faFloppyDisk}/> Speichern
                    </Button>
                </div>
            </Col>
        </Row>

        <ApiError error={error} onClose={() => setError(null)}/>
    </Form>
}
