import React, {FormEvent} from "react";
import {AxiosError} from "axios";
import {ApiClient} from "../api/ApiClient";
import {VerificationTokenDTO} from "../api/DTOs";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faCode} from "@fortawesome/free-solid-svg-icons";
import {ApiError} from "./ApiError";

type VerificationConfirmProps = {
    verificationId: string,
    setSecretId: (secretId: string) => void,
};

export const VerificationConfirm: React.FunctionComponent<VerificationConfirmProps> = ({verificationId, setSecretId}) => {
    const [token, setToken] = React.useState<number>(0);
    const [isDisabled, setDisabled] = React.useState<boolean>(false);
    const [error, setError] = React.useState<AxiosError | null>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisabled(true);

        ApiClient.verificationConfirm(
            new VerificationTokenDTO(verificationId, token),
            (res) => {
                setSecretId(res.secret);
            },
            (err) => {
                setError(err);
                setDisabled(false);
            }
        )
    };

    return <Form onSubmit={handleSubmit}>
        <div className="d-grid gap-3">
            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faCode}/>
                </InputGroup.Text>
                <Form.Control
                    type="number"
                    defaultValue={token}
                    required={true}
                    placeholder="Verifizierungscode"
                    disabled={isDisabled}
                    onChange={(e) => setToken(parseInt(e.target.value))}
                />
            </InputGroup>
            <Button type="submit" disabled={isDisabled}>
                <FontAwesomeIcon icon={faArrowRight}/> Verifizieren
            </Button>
        </div>
        <ApiError error={error} onClose={() => setError(null)}/>
    </Form>
}
