import React from "react";
import {AxiosError} from "axios";
import {ApiClient} from "../api/ApiClient";
import {UserContext} from "../context/UserContext";
import {Navigate} from "react-router-dom";
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {ApiError} from "../components/ApiError";

export const LogoutPage: React.FunctionComponent = () => {
    const [isDisabled, setDisabled] = React.useState<boolean>(false);
    const [error, setError] = React.useState<AxiosError | null>(null);

    const {user, setUser} = React.useContext(UserContext);

    if (!user) {
        return <Navigate to="/"/>
    }

    const handleLogouotClick = () => {
        setDisabled(true);

        ApiClient.logout(() => {
            setUser(null);
        }, (err) => {
            setError(err);
            setDisabled(false);
        });
    };

    return <Row>
        <Col sm={{span: 4, offset: 4}}>
            <h1>Abmelden</h1>
            <p className="lead">Bis zum nächsten Mal, {user.first_name}...</p>
            <p>Möchtest du dich wirklich abmelden?</p>
            <p>
                <Button variant="danger" onClick={handleLogouotClick} disabled={isDisabled}>
                    <FontAwesomeIcon icon={faArrowRightFromBracket}/> Abmelden
                </Button>
            </p>
            <ApiError error={error} onClose={() => setError(null)}/>
        </Col>
    </Row>
}
