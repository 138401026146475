import React from "react";
import {Navigate} from "react-router-dom";
import {ChangeUserDataForm} from "../../components/ChangeUserDataForm";
import {UserContext} from "../../context/UserContext";
import {PrivateUserDTO} from "../../api/DTOs";

export const ChangeUserDataPage: React.FunctionComponent = () => {
    const [isFinished, setFinished] = React.useState<boolean>(false);

    const {user, setUser} = React.useContext(UserContext);

    if (!user) {
        return <Navigate to="/"/>
    }

    if (isFinished) {
        return <Navigate to=".."/>
    }

    const handleSuccess = (u: PrivateUserDTO) => {
        setUser(u);
        setFinished(true);
    }

    return <ChangeUserDataForm user={user} setUser={handleSuccess}/>
}
