import React, {FunctionComponent} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import {AxiosError} from "axios";

type ErrorProps = {
    error: AxiosError | null,
    onClose?: () => void
};

export const ApiError: FunctionComponent<ErrorProps> = ({error, onClose}) => {
    if (!error) {
        return null;
    }

    let errorString;
    let errorTitle;
    if (error.response) {
        errorString = <>
            <p>Der folgende Fehler ist aufgetreten</p>
            <hr/>
            <pre>{JSON.stringify(error.response.data, null, 4)}</pre>
        </>;
        errorTitle = error.response.status + " - " + error.response.statusText;
    } else if (error.code === "ERR_NETWORK") {
        errorString = "Keine Verbindung zum Server möglich";
        errorTitle = "Netzwerkfehler";
    } else {
        errorString = <>
            <p>Folgende Informationen könnten hilfreich sein</p>
            <hr/>
            <pre>{JSON.stringify(error, null, 4)}</pre>
        </>;
        errorTitle = "Unerwarteter API-Fehler!";
    }

    return (
        <>
            <Modal show={true} animation={false} onHide={onClose} backdrop="static">
                <Modal.Header closeButton={!!onClose}>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger"/> {errorTitle}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {errorString}
                </Modal.Body>

                <Modal.Footer>
                    <p>Hey, that's all we know!</p>
                </Modal.Footer>
            </Modal>
        </>
    );
}
