import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap.min.js';
import './custom.scss';
import './index.css';
import {App} from './App';
import {BrowserRouter} from "react-router-dom";

const rootElement = document.getElementById("root");

if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    );
} else {
    alert("Could not render React application: Root element does not exist!");
}
