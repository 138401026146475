import React from "react";
import {Spinner} from "react-bootstrap";

type LoadingProps = {
    show: boolean
};

export const Loading: React.FunctionComponent<LoadingProps> = ({show}) => {
    if (!show) return null;

    return <div className="text-center">
        <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>;
}
