import React from "react";
import {Col, Row} from "react-bootstrap";

export const NotFound: React.FunctionComponent = () => {
    return (
        <Row>
            <Col sm={{span: 8, offset: 2}}>
                <div className="p-5 my-5 bg-light rounded-3">
                    <h1>404 - Not Found</h1>
                    <p className="fs-4">The website you tried to find, does not exist...</p>
                </div>
            </Col>
        </Row>
    );
}
